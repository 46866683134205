var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_unit_margin_report") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            labelCol: { span: 8 },
            wrapperCol: { span: 14 },
            form: _vm.form,
            "label-align": "left"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.branch.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.branch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.unitCode.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.unitCode.decorator,
                              expression: "formRules.unitCode.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.unitCode.name,
                            placeholder: _vm.$t(
                              _vm.formRules.unitCode.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.unitCode,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getUnitCode(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataUnitCode, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.unitCode) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.unitCode) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.invoiceDate.label) }
                    },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.invoiceDate.decorator,
                            expression: "formRules.invoiceDate.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          name: _vm.formRules.invoiceDate.name,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          type: "date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.rwoDate.label) } },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.rwoDate.decorator,
                            expression: "formRules.rwoDate.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          name: _vm.formRules.rwoDate.name,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          type: "date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.poDate.label) } },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.poDate.decorator,
                            expression: "formRules.poDate.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          name: _vm.formRules.poDate.name,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          type: "date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.customerName.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerName.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.listCustomer,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListCustomer(value)
                            },
                            change: _vm.handleChangeCustomer
                          }
                        },
                        _vm._l(_vm.dataListCustomer, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s("" + data.fullName) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.fullName) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.customerLocation.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerLocation.decorator,
                              expression: "formRules.customerLocation.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerLocation.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerLocation.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.useLocalFilter,
                            allowClear: true
                          }
                        },
                        _vm._l(_vm.dataListCustomerLocation, function(data) {
                          return _c(
                            "a-select-option",
                            { key: data.key, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.label) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.label) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.assetCategory.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.assetCategory.decorator,
                              expression: "formRules.assetCategory.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.assetCategory.name,
                            placeholder: _vm.$t(
                              _vm.formRules.assetCategory.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            allowClear: true,
                            loading: _vm.loading.category
                          },
                          on: { search: _vm.onSearchAssetCategory }
                        },
                        _vm._l(_vm.optAssetCategory, function(data) {
                          return _c(
                            "a-select-option",
                            { key: data.key, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.label) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.label) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleCancel } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.table,
                            type: "primary",
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.dataSource,
            columns: _vm.columns,
            loading: _vm.loading.table,
            pagination: {
              showTotal: function(total) {
                return _vm.$t("lbl_total_items", { total: total })
              },
              showSizeChanger: true
            },
            size: "small",
            scroll: { x: 1000 }
          },
          scopedSlots: _vm._u([
            {
              key: "currency",
              fn: function(text) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(text)))
                ])
              }
            },
            {
              key: "number",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
              }
            },
            {
              key: "date",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
              }
            },
            {
              key: "nullable",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
              }
            },
            {
              key: "expandedRowRender",
              fn: function(record) {
                return [
                  _c("a-divider", { attrs: { orientation: "left" } }, [
                    _vm._v(_vm._s(_vm.$t("lbl_invoice_ar")))
                  ]),
                  _c("a-table", {
                    attrs: {
                      "data-source": record.invoiceDto,
                      columns: _vm.invoiceColumn,
                      pagination: {
                        showTotal: function(total) {
                          return _vm.$t("lbl_total_items", { total: total })
                        },
                        showSizeChanger: true
                      },
                      "row-key": function(r) {
                        return r.arLineId
                      },
                      size: "small",
                      scroll: { x: 1000 }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "currency",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm._f("currency")(text)))
                            ])
                          }
                        },
                        {
                          key: "number",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm._f("qty")(text)))
                            ])
                          }
                        },
                        {
                          key: "date",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm._f("date")(text)))
                            ])
                          }
                        },
                        {
                          key: "nullable",
                          fn: function(text) {
                            return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _c("a-divider", { attrs: { orientation: "left" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_result_customer_maintenance")) +
                        " "
                    )
                  ]),
                  _c("a-table", {
                    attrs: {
                      "data-source": record.rwoDto,
                      columns: _vm.rwoColumn,
                      pagination: {
                        showTotal: function(total) {
                          return _vm.$t("lbl_total_items", { total: total })
                        },
                        showSizeChanger: true
                      },
                      "row-key": function(r) {
                        return r.rwoLineId
                      },
                      size: "small",
                      scroll: { x: 1000 }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "currency",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm._f("currency")(text)))
                            ])
                          }
                        },
                        {
                          key: "number",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm._f("qty")(text)))
                            ])
                          }
                        },
                        {
                          key: "date",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm._f("date")(text)))
                            ])
                          }
                        },
                        {
                          key: "nullable",
                          fn: function(text) {
                            return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _c("a-divider", { attrs: { orientation: "left" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_purchase_order")) + " ")
                  ]),
                  _c("a-table", {
                    attrs: {
                      "data-source": record.poDto,
                      columns: _vm.poColumn,
                      pagination: {
                        showTotal: function(total) {
                          return _vm.$t("lbl_total_items", { total: total })
                        },
                        showSizeChanger: true
                      },
                      "row-key": function(r) {
                        return r.poLineId
                      },
                      size: "small",
                      scroll: { x: 1000 }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "currency",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm._f("currency")(text)))
                            ])
                          }
                        },
                        {
                          key: "number",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm._f("qty")(text)))
                            ])
                          }
                        },
                        {
                          key: "date",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm._f("date")(text)))
                            ])
                          }
                        },
                        {
                          key: "nullable",
                          fn: function(text) {
                            return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ]
              }
            }
          ])
        },
        [
          _c(
            "a-descriptions",
            {
              attrs: { slot: "footer", size: "small", column: 4 },
              slot: "footer"
            },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_grand_total_dpp") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.dataReport.grandTotalDpp)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_grand_total_rwo") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.dataReport.grandTotalRwo)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_grand_total_po") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.dataReport.grandTotalPo)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_grand_total_margin") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.grandTotalMargin)
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading.download },
              on: { click: _vm.handleDownload }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }